import React from "react";
import { useRouteError } from "react-router-dom";
import classes from "../css/ErrorBoundary.module.css";
import Layout from "../components/Layout";
import TopLines from "../components/TopLines";
import SubLayout from "../components/SubLayout";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);

  const clickHandler = () => {
    // Clear all cookies
    document?.cookie?.split(";")?.forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/");
    });

    // Clear local storage
    localStorage.clear();

    window.location.href = "/";
  };
  return (
    <Layout>
      <TopLines />
      <SubLayout>
        <div className={classes.message_container}>
          <p className={classes.text}>Some Error Occured!</p>
          <p className={classes.text}>{error?.message || "Unknown Error"}</p>
          <button type="button" className={classes.btn} onClick={clickHandler}>
            Back
          </button>
        </div>
      </SubLayout>
    </Layout>
  );
};

export default ErrorBoundary;
